:root {
  --background-primary: white;
  --background-secondary: #eee;
  --text-primary: #363636;
  --text-secondary: #5a5a5a;
  --focus: #dfdddd;
  --accent: #f54545;
  --accent-rgb: 245, 69, 69;
  --border-color: #b4b4b4;
  --red: #d63031;
  --blue: #0984e3;
  --green: #55efc4;
}

[data-theme='dark'] {
  --background-primary: #363636;
  --background-secondary: #262626;
  --focus: #1d1d1d;
  --text-primary: white;
  --text-secondary: #dddddd;
  --accent: #f25c5c;
  --accent-rgb: 242, 92, 92;
  --border-color: #4e4e4e;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--background-secondary);
}

* {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: var(--text-primary);
}
input{
  background-color: var(--background-secondary);
}

p {
  margin: 2px;
}

a {
  text-decoration: none !important;
  outline: none !important;
}

/* CONTAINER BACKBUTTON */
.containerBackButton {
  position: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  top: 10px;
  padding-bottom: 20px;
}

.containerBackButton p {
  color: var(--border-color);
  font-size: 18px;
}

/* CONTAINER MOSTRAR MAIS */

.containerMostrarMais {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  padding: 10;
  cursor: pointer;
}

.containerMostrarMais p {
  color: var(--text-primary);
  font-size: 18px;
}

/* CONTAINER CARREGANDO */

.containerCarregando {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 80px;
  left: 0;
  right: 0;
}

/* CONTAINERITEM */

.containerItem {
  background-color: var(--background-primary);
  margin-bottom: 10px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  width: auto;
  display: flex;
  border-radius: 3px;
  border-left: 4px solid var(--red);
}

.containerItem table {
  width: 100%;
}

.containerItem th {
  font-size: 14px;
  width: 25%;
}

.containerItem td, .containerItem th {
  text-align: left;
}

/* CONTAINER EXTRATO */

.containerExtrato {
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-left: -20px;
}

.borderContainerExtrato {
  margin-left: 30px;
  border-left: 4px solid var(--border-color);
  padding-top: 10px;
}

.sinalExtrato {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--background-primary);
  border-radius: 4px;
}

.juntaExtrato {
  height: 3px;
  width: 10vw;
}

.containerItemExtrato {
  margin: 0;
  width: 100%
}

.valorExtrato {
  background-color: var(--blue);
  margin-left: -20px;
  border-radius: 3px;
  width: max-content;
  padding: 5px;
  text-align: center;
}

.valorExtrato p {
  color: var(--background-primary);
  padding: 0px;
  margin: 3px;
}

/* CONTAINER EXTRATO SUCATA */

.containerExtratoSucata {
  width: calc(100% - 35px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 2px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: var(--background-primary);
  padding-left: 10px;
  padding-bottom: 5px;

}

.containerExtratoSucata table {
  width: 100%;
}

.dateHeaderExtratoSucata {
  font-size: 14px;
  font-weight: 300;
}

.infoExtratoSucata {
  font-size: 18px;
  white-space: nowrap;
}

.observacaoExtratoSucata {
  display: flex;
  font-size: 12PX;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.observacaoExtratoSucata div {
  min-width: 60px;
}

.observacaoExtratoSucata div {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  width: 40px;
  justify-content: space-between;
  align-items: center;
}

/* CONTAINER EXTRATO ESTOQUE */

.containerExtratoEstoque {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-primary);
}

.containerExtratoEstoque table {
  width: 100%;
}

.dateHeaderExtratoEstoque {
  font-size: 14px;
  font-weight: 600;
}

.infoExtratoEstoque {
  font-size: 24px;
}

.observacaoExtratoEstoque {
  display: flex;
  font-size: 12px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.observacaoExtratoEstoque div {
  min-width: 60px;
}

.observacaoExtratoEstoque p {
  text-align: right;
}

.observacaoExtratoEstoque div {
  display: flex;
  flex-direction: row;
  margin-right: 30PX;
  width: 40px;
  justify-content: space-between;
  align-items: center;
}

/* MENU CONTAINER */

.menuContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background-color: var(--background-secondary);
}

.appBar {
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  background-color: var(--background-primary);
  z-index: 10;
}

.appBar a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  align-items: center;
  flex-direction: column;
  margin-bottom: 40px;
}

.appBar a p {
  transition: all .2s;
  color: var(--border-color);
}

.appBar a svg {
  transition: all .2s;
}

.appBar a:hover svg {
  fill: var(--red);
}

.appBar a:hover p {
  color: var(--text-primary);
}

.selected svg {
  fill: var(--red);
}

.selected p {
  color: var(--text-primary) !important;
}

.appBody {
  width: 100%;
  margin-bottom: 80px;
  margin-left: 80px;
  background-color: var(--background-secondary);
}

@media(max-width:720px) {
  .containerBackButton {
    width: 100%;
  }

  .menuContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
  }

  .appBar {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    background-color: var(--background-primary);
    height: 60px;
    position: fixed;
    bottom: 0px;
  }

  .appBar a {
    cursor: pointer;
    font-size: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  .appBody {
    margin-left: 0px;
    margin-bottom: 80px;
  }
}

/* LOG ITEM */
.logContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--background-primary);
  margin: 10px;
  width: calc(100% - 30px);
  border-radius: 4px;
  border-left: 5px solid var(--red);
  flex-direction: column;
  padding: 5px;
  cursor: pointer;
}

.containerDateLogs {
  background-color: var(--blue);
  width: 80px;
  padding: 5px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.containerDateLogs p {
  color: var(--background-secondary);
  font-size: 22px;
  font-weight: bold;
}


/* CONTAINER NEWOLD */
.containerNewOld {
  display: flex;
  justify-content: center;
}

.containerNewOld div {
  border-bottom: 2px solid var(--blue);
  padding: 9px;
  transition: all .2s;
  cursor: pointer;
}

.containerNewOld p {
  color: var(--border-color);
}

.containerNewOld .selected p {
  color: var(--blue) !important;
  font-weight: bold;
}

.containerNewOld div:hover {
  background-color: rgba(52, 152, 219, 0.3);
}

.containerNewOld div:hover p {
  color: var(--blue) !important;
}

@media(max-width:720px) {
  .containerNewOld {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .containerNewOld div {
    width: 100%;
    text-align: center;
  }
}

/* CONTAINER LOGIN */
.containerLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
background-color: var(--background-secondary);
}

.containerLogin form {
  display: flex;
  flex-direction: column;
}

.containerLogin img {
  margin-bottom: 50px;
  margin-top: -60px;
}

.containerLogin div:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-primary);
  padding: 10px;
  border-radius: 3px;
  height: 60%;
  min-height: 300px;
}

.containerLogin input, .containerLogin button {
  padding: 10px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  font-size: 18px;
  margin: 5px;
  min-width: 300px;
  outline: none;
}

.containerLogin button {
  width: calc(100% - 10px);
  cursor: pointer;
  border: 1px solid var(--blue);
  background-color: var(--blue);
  color: var(--background-primary);
}


/* CONTAINER TOAST */
.containerToast {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 10px;
  right: 10px;
  max-width: 500px;
  width: 50%;
  padding: 15px;
  background-color: var(--red);
  border-radius: 3px;
  justify-content: space-between;
}

.containerToast p {
  color: var(--background-primary);
}