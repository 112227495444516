.containerInfoBateria {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 80px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  max-width: -moz-available;
  background-color: var(--background-secondary);
}

.containerRight {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 20px);
  align-items: flex-end;
  overflow-y: hidden;
  padding: 10px;
  width: 40%;
  max-width: 400px;
  position: fixed;
  right: 10px;
  pointer-events: none;
}

.containerRight p {
  font-size: 18px;
  color: #aaa;
  margin: 0;
  padding: 0;
  text-align: right;
}
.containerRight b{
  color: #aaa;
}

.containerObservacao p:nth-child(1) {
  font-size: 18px;
  color: #aaa
}

.containerObservacao p:nth-child(2) {
  white-space: pre-wrap;
  height: auto;
  overflow-y: auto;
}

img {
  width: 100%;
  border-radius: 5px
}

.rhap_container {
  width: 100%;
}

.containerItems {
  min-width: 50%;
  margin-top: 40px;
}
.itemDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
}

.itemData ,.itemDataSelected{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  border-bottom: 4px solid transparent;
  transition: all .2s;
  color: #302d2d;
}

.itemDataIcon {
  margin-left: 5px;
  margin-right: 5px;
}

.itemData p:nth-child(1),.itemDataSelected {
  font-size: 16px;
}

.itemDataIcon {
  padding: 10px;
  fill: #302d2d;
  border-bottom: 4px solid transparent;
  transition: all .2s;
  fill: #e74c3c;
  
}
.datepicker >div{
  color: red !important;
}
.react-datepicker__month-text, .react-datepicker__month-2{
  color: black;
}
#selectDate input{
  /* background-color: transparent;
  border:none;
  font-size: 18px;
  color: #e74c3c;
  padding-top: 50px;
  margin-top: -50px; */
}
#selectDate{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #ccc;
  color: black !important;
}

.itemDataSelected , .itemData:hover {
  border-bottom: 4px solid #e74c3c;
}

.itemData:hover p, .itemDataIcon:hover,.itemDataSelected p{
  color: #e74c3c;
}
.loadingMsg{
  margin-top: 10px;
  font-size: 22px;
  text-align: center;
}
@media(max-width:720px) {
  .itemDataContainer{
    justify-content: normal;
  }
 
  .containerItems {
    min-width: 100%;
    margin-top: 10px;
  }
  .containerInfoBateria {
    bottom: 80px;
    left: 0;
    justify-content: center;
    flex-direction: column;
    position: static;
  }
  .containerLeft {
    width: 100%;
  }
  .containerRight {
    overflow-y: auto;
    width: auto;
    right: 0px;
    position: relative;
    max-width: 100%;
  }
  .containerRight img {
    order: 1;
  }
  .containerRight .rhap_container {
    order: 2;
  }
  .containerRight p:nth-child(1) {
    order: 3;
  }
  .containerRight p:nth-child(4) {
    order: 4
  }
  .containerObservacao {
    width: 100%;
  }
  img {
    width: 100%;
  }
  .rhap_container {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}