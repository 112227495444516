.pesquisar {
  outline: none;
  font-size: 22px;
  padding: 20px;
  padding-left: 10px;
  width: -moz-available;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #aaa;
  margin-bottom: 10px;
  width: -webkit-fill-available
}

.nenhum {
  display: flex;
  justify-content: center;
  align-items: center
}

.containerImprimir {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.imprimir {
  padding: 10px;
  border: 1px solid #3498db;
  color: #3498db;
  border-radius: 3px;
  transition: all .2s;
  justify-content: space-between;
  align-items: center;
  display: flex;
  cursor: pointer;
}

.imprimir:hover {
  background-color: rgba(52, 159, 219, 0.1);
}

.containerImprmirPrint {
  width: calc(100% - 80px);
  display: flex;
  height: 100%;
  overflow: hidden;
  position: absolute;
  right: 0;
  left: 80px;
}

@media(max-width:720px) {
  .containerImprmirPrint {
    width: 100%;
    display: flex;
    height: calc(100% - 60px);
    overflow: hidden;
    position: absolute;
    right: 0;
    left: 0px;
    bottom: 60px;
  }
}

/* IMPRIMIR */
.page{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}


.row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 90%;
  margin-bottom: 2px;
  border-bottom: 1px solid black;
}
.header p{
  font-size: 22px;
  font-weight: bold;
}

.dataEstoque {
  font-size: 14px;
  text-align: right;
  position: absolute;
  top:10px;
  right: 80px;
}
.react-datepicker__month-text, .react-datepicker__month-2{
  color: black;
}
.containerImg {
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px
}
.rowProduto{
  justify-content: flex-start;
  display: flex;
}
.rowProduto p{
  font-size: 22px;
  text-align: left;
}
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 0px;
    display: block;
    page-break-after: auto;
  }
}

@page {
  size: auto;
  margin-top: 15mm;
  margin-bottom: 20mm;
}
.total{
  text-align: right;
  width:100%;
  font-size:22px;
  font-weight:bold;
  margin-right: 70px
}