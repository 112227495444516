:root {
  --background-primary: white;
  --background-secondary: #eee;
  --text-primary: #363636;
  --text-secondary: #5a5a5a;
  --focus: #dfdddd;
  --accent: #f54545;
  --accent-rgb: 245, 69, 69;
  --border-color: #b4b4b4;
  --red: #d63031;
  --blue: #0984e3;
  --green: #55efc4;
}

[data-theme='dark'] {
  --background-primary: #363636;
  --background-secondary: #262626;
  --focus: #1d1d1d;
  --text-primary: white;
  --text-secondary: #dddddd;
  --accent: #f25c5c;
  --accent-rgb: 242, 92, 92;
  --border-color: #4e4e4e;
}
.containerInfoSucata {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  position: absolute;
  top: 0;
  bottom: 0px;
  left: 80px;
  right: 0;
  padding: 10px;
  overflow-y: auto;
  background-color: var(--background-secondary);
  max-width: -moz-available;
}
.react-datepicker__month-text, .react-datepicker__month-2{
  color: black;
}
.containerLeft {
  min-width: 50%;
  margin-top: 40px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.containerRight {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100% - 20px);
  align-items: flex-end;
  overflow-y: hidden;
  padding: 10px;
  width: 40%;
  max-width: 400px;
  position: fixed;
  right: 10px;
  pointer-events: none;
}

.containerRight p {
  font-size: 18px;
  color: var(--border-color);
  margin: 0;
  padding: 0;
}

.containerObservacao p:nth-child(1) {
  font-size: 18px;
  color: var(--border-color)
}

.containerObservacao p:nth-child(2) {
  white-space: pre-wrap;
  height: 400px;
  overflow-y: auto;
}

img {
  width: 100%;
  border-radius: 5px
}

.rhap_container {
  width: 100%;
}

.itemDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  overflow-x: auto;
  padding-bottom: 10px;
}

.itemData ,.itemDataSelected{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all .2s;
  color: var(--text-primary);
}

.itemDataIcon {
  margin-left: 10px;
  margin-right: 10px;
}

.itemData p:nth-child(1),.itemDataSelected {
  font-size: 16px !important;
}

.itemDataIcon {
  margin-bottom: -30px;
  padding: 10px;
  fill: var(--text-primary);
  border-bottom: 4px solid transparent;
  transition: all .2s;
  fill: var(--red);
  
}
#selectDate input{
  background-color: transparent;
  border:none;
  font-size: 18px;
  color: transparent;
  /* padding-top: 50px;
  margin-top: -50px;  */
  width: 50px;
  height: 50px;
  margin-top: -20px;
}
#selectDate{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 2px solid var(--border-color);
}

.itemDataSelected , .itemData:hover {
  border-bottom: 2px solid var(--red);
}

.itemData:hover p, .itemDataIcon:hover,.itemDataSelected p{
  color: var(--red);
}
.loadingMsg{
  margin-top: 10px;
  font-size: 22px;
  text-align: center;
}
@media(max-width:720px) {
  .itemDataContainer{
    justify-content: normal;
  }
  .containerInfoSucata {
    bottom: 80px;
    left: 0;
    justify-content: center;
    flex-direction: column;
    position: static;
  }
  .containerLeft {
    min-width: 100%;
    margin-top: 10px;
  }
  .containerRight {
    overflow-y: auto;
    width: auto;
    right: 0px;
    position: relative;
    max-width: 100%;
  }
  .containerRight img {
    order: 1;
  }
  .containerRight .rhap_container {
    order: 2;
  }
  .containerRight p:nth-child(1) {
    order: 3;
  }
  .containerRight p:nth-child(4) {
    order: 4
  }
  .containerObservacao {
    width: 100%;
  }
  img {
    width: 100%;
  }
  .rhap_container {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}